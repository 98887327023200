var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-center align-center flex-column"},[_c('div',{staticClass:"d-flex justify-space-around align-center mt-2"},[_c('v-card',{staticClass:"py-1 px-12 mt-1 mx-2 d-flex flex-column align-center"},[_c('div',[_vm._v(" "+_vm._s(this.$vuetify.breakpoint.lg ? 'Prev:' : 'Previsionale:')+" "),_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(!!_vm.previsional ? _vm.previsional + ' €' : '0 €'))])]),_c('div',[_vm._v(" "+_vm._s(this.$vuetify.breakpoint.lg ? 'Tot. Cli:' : 'Totale Clienti:')+" "),_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.totalCustomer))])]),_c('div',[_vm._v(" "+_vm._s(this.$vuetify.breakpoint.lg ? 'Tot. Serv:' : 'Totale Servizi:')+" "),_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.totalService))])])])],1),(_vm.type == 'pie' && _vm.loadedData && !_vm.emptyData)?_c('PieChart',{staticClass:"mt-5",attrs:{"chart-data":_vm.chartData,"styles":{
    height: _vm.height,
    width: _vm.width
  },"options":_vm.chartOptions}}):(_vm.type == 'doughnut' && _vm.loadedData && !_vm.emptyData)?_c('DoughnutChart',{staticClass:"mt-5",attrs:{"chart-data":_vm.chartData,"styles":{
    height: _vm.height,
    width: _vm.width
  },"options":_vm.chartOptions}}):_vm._e(),(!!_vm.emptyData)?_c('v-card',{staticClass:"pa-1 mt-7",attrs:{"height":_vm.height,"width":_vm.width,"outlined":""}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"text-center",staticStyle:{"width":"100%"}},[_vm._v(" DATI INSUFFICIENTI ")])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }